<template>
  <div id="app">
    <WordToPdfConverter />
  </div>
</template>

<script>
import WordToPdfConverter from './components/WordToPdfConverter.vue';

export default {
  name: 'App',
  components: {
    WordToPdfConverter
  }
};
</script>


<template>
    <div class="container">
      <h1>文档转换器</h1>
      <div class="converter-buttons">
        <div class="converter-button">
          <h2>Word 转 PDF</h2>
          <div class="dropzone" @dragover.prevent @drop.prevent="onWordDrop" @click="openFileInput('word')">
            <p v-if="!wordFile">拖放一个 Word 文件到这里或点击选择</p>
            <div v-else>
              <p>{{ wordFile.name }} ({{ getFileSize(wordFile.size) }})</p>
              <button type="button" @click="clearWordFile" class="clear-button">移除</button>
            </div>
            <input ref="wordInput" type="file" @change="onWordFileChange" accept=".doc, .docx" required style="display: none;">
          </div>
          <button type="button" @click="convertWordToPdf" class="convert-button">转换为 PDF</button>
          <div v-if="wordStatus" class="status">{{ wordStatus }}</div>
          <div v-if="pdfUrl" class="pdf-container">
            <embed :src="pdfUrl" type="application/pdf" width="100%" height="400px">
          </div>
        </div>
        <div class="converter-button">
          <h2>PDF 转 Word</h2>
          <div class="dropzone" @dragover.prevent @drop.prevent="onPdfDrop" @click="openFileInput('pdf')">
            <p v-if="!pdfFile">拖放一个 PDF 文件到这里或点击选择</p>
            <div v-else>
              <p>{{ pdfFile.name }} ({{ getFileSize(pdfFile.size) }})</p>
              <button type="button" @click="clearPdfFile" class="clear-button">移除</button>
            </div>
            <input ref="pdfInput" type="file" @change="onPdfFileChange" accept=".pdf" required style="display: none;">
          </div>
          <button type="button" @click="convertPdfToWord" class="convert-button">转换为 Word</button>
          <div v-if="pdfStatus" class="status">{{ pdfStatus }}</div>
          <div v-if="wordUrl" class="word-container">
            <iframe :src="wordUrl" width="100%" height="400px"></iframe>
          </div>
        </div>
      </div>

      <!-- 第二行开始 -->
       <!-- 第二行开始 -->
        <!-- 第二行开始 -->

    <!-- 页面底部备案信息 -->
    <footer class="footer">
      <p>京ICP备2023009778号-2</p>
    </footer>
    </div>
  </template>

  
  <script src="../js/WordToPdfConverter.js"></script>
  <style scoped src="../css/WordToPdfConverter.css"></style>
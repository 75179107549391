import axios from 'axios';


export default {
  data() {
    return {
      wordFile: null,
      pdfFile: null,
      wordStatus: '',
      pdfStatus: '',
      pdfUrl: '',
      wordUrl: ''
    };
  },
  mounted() {
    // 百度统计脚本
    var _hmt = _hmt || [];
    (function() {
      var hm = document.createElement("script");
      hm.src = "https://hm.baidu.com/hm.js?649a50e2c2adae2756b994fca922cf36";
      var s = document.getElementsByTagName("script")[0]; 
      s.parentNode.insertBefore(hm, s);
    })();
     // 广告脚本
     (function() {
      var cm = document.createElement("script");
      cm.type = "text/javascript";
      cm.src = "//cpro.baidustatic.com/cpro/ui/cm.js";
      var s = document.getElementsByTagName("script")[0]; 
      s.parentNode.insertBefore(cm, s);
    })();
  },
  methods: {
    onWordFileChange(e) {
      this.wordFile = e.target.files[0];
    },
    onPdfFileChange(e) {
      this.pdfFile = e.target.files[0];
    },
    onWordDrop(e) {
      const files = e.dataTransfer.files;
      if (files.length > 0) {
        this.wordFile = files[0];
      }
    },
    onPdfDrop(e) {
      const files = e.dataTransfer.files;
      if (files.length > 0) {
        this.pdfFile = files[0];
      }
    },
    openFileInput(type) {
      if (type === 'word') {
        this.$refs.wordInput.click();
      } else if (type === 'pdf') {
        this.$refs.pdfInput.click();
      }
    },
    getFileSize(size) {
      return (size / 1024 / 1024).toFixed(2) + ' MB';
    },
    clearWordFile() {
      this.wordFile = null;
      this.wordStatus = '';
      this.pdfUrl = '';
    },
    clearPdfFile() {
      this.pdfFile = null;
      this.pdfStatus = '';
      this.wordUrl = '';
    },
    convertWordToPdf() {
      if (!this.wordFile) {
        this.wordStatus = '请选择一个 Word 文件。';
        return;
      }
      this.wordStatus = '正在转换...';
      const formData = new FormData();
      formData.append('wordFile', this.wordFile);

      axios.post('http://127.0.0.1:8081/upload/wordtopdf', formData, {
        headers: {
          'Content-Type': 'multipart/form-data'
        }
      })
      .then(response => {
        this.wordStatus = '转换成功！';
        this.pdfUrl = URL.createObjectURL(response.data.pdf);
      })
      .catch(error => {
        this.wordStatus = '转换失败！';
        console.error('错误:', error);
      });
    },
    convertPdfToWord() {
      if (!this.pdfFile) {
        this.pdfStatus = '请选择一个 PDF 文件。';
        return;
      }
      this.pdfStatus = '正在转换...';
      const formData = new FormData();
      formData.append('pdfFile', this.pdfFile);

      axios.post('http://127.0.0.1:8081/upload/pdftoword', formData, {
        headers: {
          'Content-Type': 'multipart/form-data'
        }
      })
      .then(response => {
        this.pdfStatus = '转换成功！';
        this.wordUrl = URL.createObjectURL(response.data.word);
      })
      .catch(error => {
        this.pdfStatus = '转换失败！';
        console.error('错误:', error);
      });
    }
  }
};